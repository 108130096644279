package model

import kotlinx.serialization.Serializable

@Serializable
data class DailyOffer(
    var receivedType: Int? = null,
    var receivedAmount: String? = null,
    var percentageReceived: String? = null,
    var pointReceived: String? = null,
    var message: String? = null,
)
