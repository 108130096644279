package receipt

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class QuestionAnswer(
    @SerialName("orderPaymentGuid")
    val orderPaymentGuid: String,

    @SerialName("questionId")
    val questionId: Int,

    @SerialName("score")
    val score: Int,
)
