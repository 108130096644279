package model

import kotlinx.serialization.Serializable

@Serializable
data class MerchantSummaryInformation(
    val name: String? = "",
    val phoneNumber: Long? = -1,
    val address: String? = "",
    val loyaltyProgramJoinMessage: String? = "",
    val dailyOffer: DailyOffer? = DailyOffer()
)