package navigation

sealed class NavigationState(
    val title: String,
    val poppable: Boolean = true,
    val guid: String,
) {
    class Blank : NavigationState("", false, "")
    class Login : NavigationState("Login", false, "")
    class LoginSubmitted : NavigationState("", false, "")
    class Auth(authGuid: String) : NavigationState("Auth", false, authGuid)
    class AuthFailed : NavigationState("", false, "")
    class ConsumerInformation : NavigationState("ConsumerInformation", false, "")
    class MerchantInformation(consumerLoyaltyProgramGuid: String) : NavigationState("MerchantInformation", guid = consumerLoyaltyProgramGuid)
    class EReceipt(orderPaymentGuid: String) : NavigationState("EReceipt", guid = orderPaymentGuid)
    class TermsOfService : NavigationState("TermsOfService", guid = "")
    class PrivacyPolicy : NavigationState("PrivacyPolicy", guid = "")
    class Search : NavigationState("Search", guid = "")
    class Exit : NavigationState("Exit", false, "")

    companion object {
        fun fromHash(hash: String): NavigationState? {
            var location: String = ""
            var guid: String = ""
            if (hash.contains('=')) {
                hash.split("=").let { location = it.first(); guid = it.last() }
            } else if (hash.isEmpty()) {
                location = "Login"
            } else {
                location = hash
            }

            return when (location.lowercase()) {
                "Login".lowercase() -> Login()
                "Auth".lowercase() -> Auth(guid)
                "ConsumerInformation".lowercase() -> ConsumerInformation()
                "MerchantInformation".lowercase() -> MerchantInformation(guid)
                "EReceipt".lowercase() -> EReceipt(guid)
                "TermsOfService".lowercase() -> TermsOfService()
                "PrivacyPolicy".lowercase() -> PrivacyPolicy()
                "Search".lowercase() -> Search()
                else -> null.also { println("Invalid location in: '$hash'") }
            }
        }
    }

    fun asUrlString(): String {
        return when {
            this is ConsumerInformation -> title
            this is Login -> ""
            this is Blank -> title
            this is AuthFailed -> title
            this is LoginSubmitted -> title
            this is TermsOfService -> title
            this is PrivacyPolicy -> title
            this is Exit -> title
            this is Search -> title
            else -> """${title}=${guid}"""
        }
    }

    override fun toString(): String {
        return "NavigationState(title='$title', poppable=$poppable, guid='$guid')"
    }

    override fun equals(other: Any?): Boolean {
        if (other is NavigationState) {
            if (title == other.title && guid == other.guid) {
                return true
            }
        }
        return false
    }
}