package ui

import androidx.compose.ui.graphics.Color

class Colors {
    companion object {
        val Primary = Color(0xFF29B505)
        val Secondary = Color(0xFF4D4FE5)
        val Highlight = Color(0xFFDBE3EF)
        val Default = Color(0xFFF0F2F5)
        val PrivacyBlue = Color(0xFF50A9C9)
        val EreceiptGreen = Color(0xFFC0E0B8)
        val TransactionBorderStroke= Color(0xFFBDC5CD)
        val alertBanner = Color(0xFF0460D7)
    }
}
