package ui.search

import androidx.compose.foundation.LocalIndication
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.material3.ElevatedCard
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import model.MerchantSummaryInformation
import toPhoneNumberFormat
import ui.Colors

@Composable
fun MerchantNearMeCard(
    store: MerchantSummaryInformation,
    onMerchantClicked: (MerchantSummaryInformation) -> Unit = {},
    shouldDeactivateClickEffects: Boolean = false,
) {
    ElevatedCard(
        modifier = Modifier
            .fillMaxWidth()
            .height(116.dp)
            .padding(horizontal = 16.dp)
            .clickable(
                interactionSource = remember { MutableInteractionSource() },
                indication = if (!shouldDeactivateClickEffects) LocalIndication.current else null,
                enabled = true,
                onClickLabel = null,
            ) {
                onMerchantClicked(store)
            }
    ) {
        Row(
            modifier = Modifier
                .background(Colors.Highlight)
                .fillMaxSize()
        ) {
            Column(
                verticalArrangement = Arrangement.SpaceEvenly,
                modifier = Modifier.fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 8.dp)
                    .fillMaxHeight()
                    .weight(1f),
            ) {
                store.name?.let { Text(it, fontWeight = FontWeight.Bold) }
                store.address?.let { Text(it, maxLines = 3, overflow = TextOverflow.Ellipsis) }
                store.phoneNumber?.let { Text(it.toPhoneNumberFormat()) }
            }
            if (store.dailyOffer?.receivedType != null) {
                DailyOffer(store)
            }
        }
    }
}