package ui.search

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.flow.Flow
import model.MerchantSummaryInformation
import ui.scrollbar.LazyColumnScrollbar

@Composable
fun SearchScreen(
    merchantsStateFlow: Flow<List<MerchantSummaryInformation>?>,
    shouldShowScrollbar: Boolean,
    searchText: Flow<String>,
    onSearchTextChange: (String) -> Unit,
) {
    val _merchants by merchantsStateFlow.collectAsState(null)
    val merchants = _merchants

    val _searchText by searchText.collectAsState(null)
    val searchText = _searchText ?: ""

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        val lazyListState = rememberLazyListState()

        OutlinedTextField(
            value = searchText,
            onValueChange = onSearchTextChange,
            label = {
                Text(text = "Merchants Near Me")
            },
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
            shape = RoundedCornerShape(0.dp),
            modifier = Modifier.onPreviewKeyEvent {
                when {
                    (it.key == Key.Enter && it.type == KeyEventType.KeyUp) -> {
                        true
                    }
                    else -> {
                        false
                    }
                }
            },
        )

        if (merchants != null) {
            LazyColumnScrollbar(
                listState = lazyListState,
                padding = 4.dp,
                alwaysShowScrollBar = shouldShowScrollbar,
            ) {
                LazyColumn(
                    state = lazyListState,
                    modifier = Modifier
                ) {
                    item {
                        Text(
                            "Stores Near Me",
                            fontWeight = FontWeight.ExtraBold,
                            modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp, bottom = 16.dp),
                            fontSize = 20.sp
                        )
                    }
                    items(merchants) { merchant ->
                        MerchantNearMeCard(merchant, shouldDeactivateClickEffects = true)
                        Divider(
                            color = Color.White,
                            modifier = Modifier
                                .height(16.dp)
                        )
                    }
                }
            }
        } else {
            CircularProgressIndicator()
        }
    }
}