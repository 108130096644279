import io.ktor.client.HttpClient
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import model.impl.DefaultDataRepository
import model.DataRepository
import model.NetworkController
import model.impl.NetworkControllerKtor
import model.network.FlavorInjectedHttpClient
import navigation.Navigation
import org.koin.core.module.dsl.bind
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.module
import persistence.MultiPlatformSettingsPersistence
import persistence.Persistence
import persistence.TokenPersistence
import ui.AppViewModel
import ui.login.LoginViewModel

val appModules = module {
    factory<CoroutineDispatcher> { Dispatchers.Default }
    single<CoroutineScope> { CoroutineScope(get<CoroutineDispatcher>() + SupervisorJob() + globalCoroutineExceptionHandler) }

    single<HttpClient> { FlavorInjectedHttpClient(get()).getClient() }
    single<Flavor> { getFlavor() }
    single<ConfigFile> { getClientConfig() }

    factoryOf(::NetworkControllerKtor) { bind<NetworkController>() }
    singleOf(::TokenPersistence)
    singleOf(::DefaultDataRepository) { bind<DataRepository>() }
    single { params -> Navigation(params.get(), params.get()) }
    singleOf(::MultiPlatformSettingsPersistence) { bind<Persistence>() }

    singleOf(::AppViewModel)
    singleOf(::LoginViewModel)
}