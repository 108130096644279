package model.impl

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.bearerAuth
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.path
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import model.ConsumerInformation
import model.LoginResponse
import model.MerchantSummaryInformation
import model.NetworkController
import model.Payments
import model.ReferredBusiness
import model.SmsAuthenticationBody
import model.TokenAuthenticationBody
import model.TokenAuthenticationResponse
import persistence.TokenPersistence
import receipt.Question
import receipt.QuestionAnswer
import receipt.Receipt

class NetworkControllerKtor(
    private val httpClient: HttpClient,
    private val tokenPersistence: TokenPersistence,
) : NetworkController {

    class CachedConsumerInformationForToken(val token: String, val consumerInformation: ConsumerInformation)

    private var consumerInformationCached: CachedConsumerInformationForToken? = null

    override suspend fun getConsumerInformation(): ConsumerInformation? {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        if (consumerInformationCached?.token != token) {
            val response = httpClient.get {
                bearerAuth(token)
                url {
                    path("consumerInformation")
                }
            }
            if (response.status.value in 200..299) {
                consumerInformationCached =
                    CachedConsumerInformationForToken(token, Json.decodeFromString<ConsumerInformation>(response.bodyAsText()))
            } else {
                println("Invalid response from server: ${response.bodyAsText()}")
            }
        }
        return consumerInformationCached?.consumerInformation
    }

    override suspend fun getMerchantInformation(consumerLoyaltyProgramGuid: String, forceRefresh: Boolean): Payments {
        val response = httpClient.get {
            bearerAuth(tokenPersistence.getToken()!!)
            url {
                path("orderInformation")
                parameters.append("consumerLoyaltyProgramGuid", consumerLoyaltyProgramGuid)
            }
        }
        return Json.decodeFromString<Payments>(response.bodyAsText())
    }

    override suspend fun getEReceiptInformation(orderPaymentGuid: String, forceRefresh: Boolean): Receipt {
        val response = httpClient.get {
            url {
                path("eReceipt")
                parameters.append("orderPaymentGuid", orderPaymentGuid)
            }
        }
        return Json.decodeFromString<Receipt>(response.bodyAsText())
    }

    override suspend fun getQuestions(orderPaymentGuid: String): List<Question> {
        val response = httpClient.get {
            url {
                path("questions")
                parameters.append("orderPaymentGuid", orderPaymentGuid)
            }
        }
        return Json.decodeFromString<List<Question>>(response.bodyAsText())
    }

    override suspend fun answerQuestion(questionAnswer: QuestionAnswer) {
        httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("answerQuestion")
            }
            setBody(Json.encodeToString(questionAnswer))
        }
    }

    override suspend fun sendBusinessEnrollmentInformation(referredBusiness: ReferredBusiness) {
        val response = httpClient.get {
            url {
                path("referredBusiness")
                parameters.append("potentialCustomer", Json.encodeToString(referredBusiness))
            }
        }
        println(response)
        return Unit
    }

    override suspend fun submitPhoneNumber(phoneNumber: Long): LoginResponse {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("sms-authentication")
            }
            setBody(Json.encodeToString(SmsAuthenticationBody(phoneNumber)))
        }
        return when {
            response.status != io.ktor.http.HttpStatusCode.OK -> LoginResponse.Error(response.status.description)
            response.body<String>().lowercase() == "Ok".lowercase() -> LoginResponse.Submitted()
            else -> LoginResponse.BypassLogin(response.body<TokenAuthenticationResponse>().token)
        }
    }

    override suspend fun getMerchantsNearMe(): List<MerchantSummaryInformation> {
        val response = httpClient.post {
            bearerAuth(tokenPersistence.getToken()!!)
            contentType(ContentType.Application.Json)
            url {
                path("merchants-near-me")
            }
        }
        return response.body<List<MerchantSummaryInformation>>()
    }

    override suspend fun authenticateGuid(guid: String): String {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("authentication-response")
            }
            setBody(TokenAuthenticationBody(guid))
        }
        return response.body<TokenAuthenticationResponse>().token
    }

    override suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String) {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("manageAccount")
                parameters.append("orderPaymentGuid", guid)
            }
        }
    }
}