package ui.consumerInformation

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import model.Card
import model.CardType
import org.jetbrains.compose.resources.painterResource
import ui.Colors.Companion.TransactionBorderStroke

@Composable
fun CardDisplayView(item: Card, cardViewHeight: Dp) {
    val cardVerticalPadding = 8.dp
    Card(
        modifier = Modifier
            .size(width = 100.dp, height = cardViewHeight - (cardVerticalPadding * 2))
            .padding(vertical = cardVerticalPadding),
        border = BorderStroke(1.dp, TransactionBorderStroke)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .padding(horizontal = 4.dp)
        ) {
            val image = CardType.getDrawable(item.panMasked?.substring(0, 1))
            Image(
                painter = painterResource(image),
                contentDescription = "",
                modifier = Modifier.size(70.dp)
            )
            if (item.panMasked != null) {
                Text(item.panMasked!!.takeLast(8))
            }
        }
    }
}