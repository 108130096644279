package ui.consumerInformation

import androidx.compose.foundation.LocalIndication
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.LinearProgressIndicator
import androidx.compose.material.Text
import androidx.compose.material3.ElevatedCard
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import model.MerchantFullInformation
import toPhoneNumberFormat
import ui.Colors

@Composable
fun MerchantCard(
    store: MerchantFullInformation,
    onMerchantClicked: (MerchantFullInformation) -> Unit = {},
    shouldDeactivateClickEffects: Boolean = false,
) {
    ElevatedCard(
        modifier = Modifier
            .fillMaxWidth()
            .height(168.dp)
            .padding(horizontal = 16.dp)
            .clickable(
                interactionSource = remember { MutableInteractionSource() },
                indication = if (!shouldDeactivateClickEffects) LocalIndication.current else null,
                enabled = true,
                onClickLabel = null,
            ) {
                onMerchantClicked(store)
            }
    ) {

        Column(modifier = Modifier.fillMaxWidth()) {
            Column(
                modifier = Modifier
                    .background(Colors.Highlight)
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 8.dp)
                    .weight(1f)
            ) {
                Text(store.name!!, fontWeight = FontWeight.Bold)
                Text(store.address!!)
                Text(store.phoneNumber!!.toPhoneNumberFormat())
            }
            BalanceSection(store)
        }
    }
}

@Composable
fun BalanceSection(
    store: MerchantFullInformation,
    backgroundColor: Color = Colors.Secondary,
    textColor: Color = Color.White,
    progressBarColor: Color = Colors.Primary,
    fontWeight: FontWeight = FontWeight.Normal,
) {
    Column(
        modifier = Modifier
            .background(backgroundColor)
            .fillMaxWidth()
            .heightIn(min = 56.dp)
            .padding(vertical = 8.dp),
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            "Balance: ${store.rewardBalanceCurrent?.formatDecimalToCurrency()}",
            modifier = Modifier
                .align(Alignment.CenterHorizontally),
            color = textColor,
            fontWeight = fontWeight,
        )

        if (store.points != null && store.points?.requiredPoints != null) {
            Divider(color = Color.Transparent, modifier = Modifier.height(8.dp))
            val progress = (store.points!!.currentPointBalance / store.points!!.requiredPoints)
            LinearProgressIndicator(
                progress = progress.toFloat(),
                strokeCap = StrokeCap.Round,
                color = progressBarColor,
                backgroundColor = Color.White,
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .height(8.dp)
                    .fillMaxWidth()
                    .clip(RoundedCornerShape(14.dp)),
            )
            Text(
                "${store.points!!.currentPointBalance} / ${store.points!!.requiredPoints} points",
                modifier = Modifier
                    .align(Alignment.End)
                    .padding(end = 16.dp),
                color = textColor,
                fontWeight = fontWeight,
            )
        }
    }
}