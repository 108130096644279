package receipt

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Question(
    @SerialName("questionId")
    val questionId: Int,

    @SerialName("display")
    val display: String? = null,

    @SerialName("questionType")
    val questionType: Int,

    @SerialName("statusType")
    val statusType: Int,
)