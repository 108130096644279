package ui.eReceipt

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.ExitTransition
import androidx.compose.animation.fadeIn
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.Flow
import model.MerchantFullInformation
import takeIfNotBlank
import ui.Colors
import ui.consumerInformation.BalanceSection

@Composable
fun Balance(
    rewardBalanceMessage: String?,
    merchantInformationStateFlow: Flow<MerchantFullInformation?>,
    newConsumerIdMatchesCurrent: Boolean
) {
    val _store by merchantInformationStateFlow.collectAsState(initial = null)
    val store = _store
    val initialLoadingState by remember { mutableStateOf(store == null) }
    Divider(
        modifier = Modifier
            .height(16.dp)
            .widthIn(0.dp, maxWidth),
        color = Color.White,
    )
    Box {
        AnimatedVisibility(
            visible = store != null,
            enter = fadeIn(
                initialAlpha = if (initialLoadingState) 1f else 0f
            ),
            exit = ExitTransition.None,
        ) {
            if (newConsumerIdMatchesCurrent) {
                Box(modifier = Modifier.width(maxWidth)) {
                    rewardBalanceMessage?.takeIfNotBlank()?.let {
                        RecapLanguageBanner(it)
                    }
                }
            }
            if (store != null && !newConsumerIdMatchesCurrent) {
                Box(modifier = Modifier.width(maxWidth)) {
                    BalanceSection(
                        store,
                        backgroundColor = Colors.Highlight,
                        textColor = Color.Black,
                    )
                }
            }
        }
        if (store == null) {
            rewardBalanceMessage?.takeIfNotBlank()?.let {
                RecapLanguageBanner(it)
            }
        }
    }
}