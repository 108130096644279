package ui.login

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import globalCoroutineExceptionHandler
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import model.DataRepository
import model.LoginResponse
import stripNonNumbers

class LoginViewModel(
    private val dataRepository: DataRepository,
) : ViewModel() {

    private val phoneNumberMutableStateFlow = MutableStateFlow("")
    val phoneNumberState = phoneNumberMutableStateFlow.asStateFlow()

    private val isButtonEnableMutableStateFlow = MutableStateFlow(false)
    val isButtonEnableStateFlow = isButtonEnableMutableStateFlow.asStateFlow()

    private val countryCodeMutableStateFlow = MutableStateFlow(1)
    val countryCodeStateFlow = countryCodeMutableStateFlow.asStateFlow()

    fun updatePhoneNumber(phoneNumber: String) {
        var formattedPhoneNumber = phoneNumber.stripNonNumbers()
        if (formattedPhoneNumber.length > 10){
            formattedPhoneNumber = formattedPhoneNumber.substring(0, 10)
        }
        phoneNumberMutableStateFlow.value = formattedPhoneNumber
        isButtonEnableMutableStateFlow.value = formattedPhoneNumber.length >= 10
    }

    fun submitPhoneNumber(onSubmitted: () -> Unit) {
        if (isButtonEnableStateFlow.value) {
            onSubmitted()
            val phoneNumberWithCountryCode = "${countryCodeStateFlow.value}${phoneNumberState.value}"
            val validatedPhoneNumber = phoneNumberWithCountryCode.toLongOrNull()
            if (validatedPhoneNumber != null) {
                viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                    val tokenResponse = dataRepository.submitPhoneNumber(validatedPhoneNumber)
                    when (tokenResponse) {
                        is LoginResponse.Submitted -> Unit
                        is LoginResponse.BypassLogin -> dataRepository.bypassLogin(tokenResponse.token)
                        is LoginResponse.Error -> Unit
                    }
                }
            }
        }
    }

    fun countryCodeSelected(countryCode: Int) {
        countryCodeMutableStateFlow.value = countryCode
    }
}